import Swiper, { Navigation, Pagination, Lazy, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Lazy, Autoplay]);

export default function setIcons() {
    const icons = document.querySelectorAll('[data-icons-carousel]');

    let autoplay = {
      delay: 3000,
      disableOnInteraction: true
    }

    let loop = true;

    if (window.innerWidth > 767) {
      autoplay = false;
      loop = false;
    }

    icons.forEach(section => {
    const carousel = section.querySelector('.swiper');
    const pagination = section.querySelector('.swiper-pagination');
    const next = section.querySelector('.swiper-button-next');
    const prev = section.querySelector('.swiper-button-prev');

    new Swiper(carousel, {
        slidesPerView: 1,
        spaceBetween: 20,
        watchOverflow: true,
        preloadImages: false,
        speed: 1000,
        loop,
        autoplay,
        lazy: {
            checkInView: true,
            loadPrevNext: false,
            loadOnTransitionStart: true,
        },
        navigation: {
            nextEl: next,
            prevEl: prev,
            disabledClass: '-disabled'
        },
        pagination: {
            el: pagination,
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
          480: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 45
          },
        }
    });
  });
}
