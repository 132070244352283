import setFullbanner from './home/fullbanner';
import setIcons from './home/icons';
import setCarousel from '../components/carousel';
import setInstagram from './home/instagram';
//addImports

const Home = {
  init: function () {
    setFullbanner()
    setIcons();
    setCarousel();
    setInstagram();
    //calls
  },
};

window.addEventListener('DOMContentLoaded', () => {
  Home.init()
})
